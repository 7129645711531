import {
  cardTitle,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const bookingFormStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
    ...buttonStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  panelDescription: {
    fontSize: 'larger',
    marginBottom: '20px',
    color: '#3C4858',
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  mrAuto: {
    marginRight: "auto"
  },
  mlAuto: {
    marginLeft: "auto"
  },
  labelLeft: {
    float: "left"
  },
  addPatient: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer"
  }
};

export default bookingFormStyle;
