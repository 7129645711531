import Send from "@material-ui/icons/Send";
import Reply from "@material-ui/icons/Reply";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Today from "@material-ui/icons/Today";
import Edit from "@material-ui/icons/Edit";
import React from "react";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import moment from "moment";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    action: {
        display: "flex",
        alignItems: "center"
    },
    actionText: {
        paddingLeft: 15
    },
};

function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const getPaymentStatus = booking => {
    if (booking.payment_method !== "private") {
        return capitalize(booking.payment_method.replace(/_/g, " "));
    }

    if (booking.billing && booking.billing.payment_status) {
        return capitalize(booking.billing.payment_status);
    }

    return "Private";
};

export const bookingActions = (booking, roles=null) => {
        if (booking.booking_type === "individual consult")
        {
            const datetime = moment(booking.datetime);
            const cut_off_datetime = moment().add(1, 'minute');

            let elements = [
                <div style={styles.action} actionid="resend-booking-patient" id={booking.id}><Send/><span
                  style={styles.actionText}>Resend booking to Patient</span></div>,
                <div style={styles.action} actionid="resend-booking-practitioner" id={booking.id}>
                    <Reply/><span
                  style={styles.actionText}>Resend booking to Practitioner</span></div>,
                { divider: true },
                <div style={styles.action} actionid="billing-completed" id={booking.id}><Check/><span
                  style={styles.actionText}>Billing Completed</span></div>,
                <div style={styles.action} actionid="cancel-booking" id={booking.id}><Close/><span
                  style={styles.actionText}>Cancel Booking</span></div>,
            ]
            if (booking.metadata?.reschedulable && datetime > cut_off_datetime && booking.finalized !== "Yes"){
                elements.push(
                    <div style={styles.action} actionid="open-reschedule-dialog" id={booking.id}><Today/><span
                  style={styles.actionText}>Reschedule Booking</span></div>
                )
            }
            return elements
        }

        if (booking.booking_type === "group consult participant")
            return [
                <div style={styles.action} actionid="resend-booking-patient" id={booking.id}><Send/><span
                  style={styles.actionText}>Resend booking to Patient</span></div>,
                { divider: true },
                <div style={styles.action} actionid="cancel-booking" id={booking.id}><Close/><span
                  style={styles.actionText}>Cancel Booking</span></div>
            ]

        return [
            <div style={styles.action} actionid="edit-group-booking" id={booking.id}><Edit/><span
              style={styles.actionText}>Edit Group Booking</span></div>,
          <div style={styles.action} actionid="resend-booking-practitioner" id={booking.id}>
                    <Reply/><span
                  style={styles.actionText}>Resend booking to Practitioner</span></div>,
            { divider: true },
          <div style={styles.action} actionid="billing-completed" id={booking.id}><Check/><span
                  style={styles.actionText}>Billing Completed</span></div>,
            <div style={styles.action} actionid="cancel-booking" id={booking.id}><Close/><span
              style={styles.actionText}>Cancel Booking</span></div>
        ]
    };

export const text2HTML = (text) => {
    // 1: Plain Text Search
    text = text.replace(/&/g, "&amp;").
    replace(/</g, "&lt;").
    replace(/>/g, "&gt;");

    // 2: Line Breaks
    text = text.replace(/\r\n?|\n/g, "<br>");

    // 3: Paragraphs
    text = text.replace(/<br>\s*<br>/g, "</p><p>");

    return text;
}