import React, { useEffect, useState } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Replay from "@material-ui/icons/Replay";
import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import EventIcon from "@material-ui/icons/Event";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";
import { PractitionersContext } from "layouts/Admin";

import { clearBookingsForm, makeBooking, setBookingFormField, fetchPractitionerAvailability, makeCalendarBooking } from "../../../redux/actions/bookings";


import PatientFormFields from "./PatientFormFields";
import CustomInput from "../../../components/CustomInput/CustomInput";
import RenderAlert from "./../renderAlert";
import moment from "moment";
import Loader from "react-loader-spinner";



const useStyles = makeStyles(styles);
export default function ExtendedForms() {

    const dispatch = useDispatch();


    const alert = useSelector(state => state.bookings.alert);
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const userName = useSelector(s => s.auth?.login_info?.token_data?.user?.name);

    const formFields = useSelector(state => state.bookings.bookingFormFields);

    const practitioners = React.useContext(PractitionersContext);
    const duplicateParticipant = formFields && formFields['selectedPractitioner'] && formFields['selectedPractitioner']['name'] === formFields['name'];
    const is_calendar_user = useSelector(state => state.telehealth.is_calendar_user);
    const calendar_user_availability = useSelector(state => state.bookings.calendar_user_availability);
    const [practitionerSelected, setPractitionerSelected] = React.useState(null);

    const isFormInvalid = (formFields) => {
        if (formFields['bookingType'] === "individual consult"){
            if (formFields["selectedPractitioner"] === null || formFields["name"] === "" || formFields["email"] === "" || formFields["contactNumber"] === "")
                return true;

            if (formFields["paymentMethod"] === "private" && formFields["autoPayment"] && (formFields["tariffCode"] === "" || formFields["billingAmount"] < 5)) {
                return true;
            }
        }
        if (formFields['bookingType'] === "group consult facilitator"){
            if (formFields["selectedPractitioner"] === null || formFields["groupName"] === "")
                return true;

            if (formFields["paymentMethod"] === "private" && formFields["autoPayment"] && (formFields["tariffCode"] === "" || formFields["billingAmount"] < 5)) {
                return true;
            }
        }

        if (practitionerSelected?.is_calendar_user && (formFields["dateTimeSlot"] === "" ||formFields["dateTimeSlot"] === null )){
            return true;
        }

        return false;
    };

    const isInvalid = isFormInvalid(formFields) || duplicateParticipant;

    const [booking_type, setBookingType] = useState(formFields['bookingType'] || 'individual consult');
    React.useEffect(() => {
        dispatch(clearBookingsForm());
        dispatch(setBookingFormField("datetime", Datetime.moment().subtract(1, "day")))
    }, []);

    useEffect(() => {
        if (formFields['bookingType'] !== booking_type){
            setBookingType(formFields['bookingType'])
        }
    }, [formFields['bookingType']]);

    if (!is_calendar_user && formFields['datetime'] < Datetime.moment().startOf('day') ){
        const remainder = 5 - (Datetime.moment().minute() % 5);
        dispatch(setBookingFormField("datetime", Datetime.moment().add(remainder, "minute")))
    }

    const practitionerSelectionFilterOptions = createFilterOptions({
        stringify: p => `${p.practice.site.name} ${p.practice.name} ${p.name}`
    });

    const handlePractitionerSelected = (event, newValue) => {
        dispatch(setBookingFormField("selectedPractitioner", newValue));
        setPractitionerSelected(newValue);
        if (newValue.is_calendar_user) {
            dispatch(fetchPractitionerAvailability(newValue));
        }
    };


    const classes = useStyles();

    const onCalendarUserTimeSlotChange = (dt) => {
        dispatch(setBookingFormField("dateTimeSlot", dt));
    };

    const renderPractitioner = (practitioner) => {
        return  (
            <div className={classes.container}>{practitioner.name} {practitioner.is_calendar_user ? <EventIcon fontSize={"small"}/> : null}</div>
        )
    };

    const renderTimeSelect = () => {
        if (practitionerSelected !== null) return practitionerSelected?.is_calendar_user ? <CalendarUserDateTimeSelect/> :
            <>
                <InputLabel className={classes.label}>Select a date and time</InputLabel>
                  <br/>
                <FormControl fullWidth>
                      <Datetime
                        inputProps={{ placeholder: "" }}
                        timeConstraints={{
                            minutes: {
                                step: 5
                            }
                        }}
                        value={formFields["datetime"]}
                        onChange={(dt) => dispatch(setBookingFormField("datetime", dt))}
                        dateFormat={"dddd, MMMM Do YYYY [at]"}
                        timeFormat={"h:mm a"}
                        isValidDate={(current) => current.isAfter(Datetime.moment().subtract(1, "day"))}
                      />
                    </FormControl>
            </>
        return null;
    };

    const CalendarUserDateTimeSelect = () => {
        const hasBookingSlots = !!(calendar_user_availability?.length)
        return ( <>
            <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        >

                      <Autocomplete
                        options={calendar_user_availability ? calendar_user_availability : []}
                        getOptionLabel={(p) => p ? `${moment(p.start).format('dddd, DD MMMM YYYY')}, ${moment(p.start).format('HH:mm')} - ${moment(p.end).format('HH:mm A')}` : ""}
                        value={formFields["dateTimeSlot"]}
                        onChange={(event, newValue) => onCalendarUserTimeSlotChange(newValue)}
                        openOnFocus={true}
                        groupBy={(p) => `${moment(p.start).format('dddd, DD MMMM YYYY')}`}
                        // filterOptions={practitionerSelectionFilterOptions}
                        renderOption={(o) => <p>{moment(o.start).format('HH:mm')} - {moment(o.end).format('HH:mm A')}</p>}
                        disabled={!!bookingsLoading || !hasBookingSlots}
                        renderInput={(params) => <TextField
                          label={ bookingsLoading ? "Loading Avaliable Timeslots..." : (hasBookingSlots ? "Choose a Timeslot" : "No Booking Slots Avaliable")}
                          {...params}
                          InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment 
                                    style={ bookingsLoading ? {marginRight: "-18px"} : (hasBookingSlots? {marginRight: "-56px"}: {marginRight: "-28px"}) } 
                                    position="end">
                                    { bookingsLoading ? 
                                    <Loader
                                        type="ThreeDots"
                                        color="#00acc1"
                                        width={25}
                                        timeout={15000} //15 secs
                                    /> :
                                    <IconButton onClick={() => dispatch(fetchPractitionerAvailability(practitionerSelected))}>
                                      <Replay />
                                    </IconButton>
                                    }

                                </InputAdornment>
                              ),
                              autoComplete: "new-password" // Avoid Auto Complete
                          }}
                          InputLabelProps={{ classes: {root: classes.endAdornment}}}
                        />}
                      />
                    </FormControl>
        </>
        )
    };


    const handleSubmit = () => {
        const payload = {
            practitioner_id: formFields["selectedPractitioner"].id,
            practice_id: formFields["selectedPractitioner"].practice.id,
            site_id: formFields["selectedPractitioner"].practice.site.id,
            organization_id: formFields["selectedPractitioner"].practice.organization.id,
            datetime: formFields["datetime"].format(),
            datetime_slot: formFields["dateTimeSlot"],
            patient_name: formFields["name"],
            patient_contact_number: formFields["contactNumber"],
            patient_number: formFields['patientNumber'],
            patient_email: formFields["email"],
            payment_method: formFields["paymentMethod"],
            auto_payment: formFields["autoPayment"],
            booking_created_by: userName,
            booking_type: formFields['bookingType'],
            group_name: formFields['groupName'],
            metadata: {"reschedulable": true},
        };

        if (payload.auto_payment) {
            payload["billing_amount"] = formFields["billingAmount"];
            payload["tariff_code"] = formFields["tariffCode"];
        }

        if (practitionerSelected?.is_calendar_user){
            dispatch(makeCalendarBooking(payload))
        }
        else {
            dispatch(makeBooking(payload));
        }
    };

    const renderForm = () => {

        if (practitioners === null || practitioners === undefined || practitioners.length === 0) {
            return <p>Loading Practitioners...</p>;
        }

        return (
          <form>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >

                  <Autocomplete
                    options={practitioners}
                    getOptionLabel={(p) => p ? `[${p.practice.site.name} - ${p.practice.name}] ${p.name}` : ""}
                    value={formFields["selectedPractitioner"]}
                    onChange={handlePractitionerSelected}
                    openOnFocus={true}
                    groupBy={(p) => `${p.practice.site.name} - ${p.practice.name}`}
                    filterOptions={practitionerSelectionFilterOptions}
                    renderOption={(o) => renderPractitioner(o)}
                    renderInput={(params) => <TextField
                      label="Choose a Practitioner"
                      {...params}
                      inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // Avoid Auto Complete
                      }}
                    />}
                  />
              </FormControl>

              {renderTimeSelect()}

              {booking_type === "individual consult" ?
                <PatientFormFields formFields={formFields} duplicateParticipant={duplicateParticipant}/> :
                <CustomInput
                  labelText="Group Name"
                  id="group_name"
                  formControlProps={{
                      fullWidth: true
                  }}
                  inputProps={{
                      type: "text",
                      autoComplete: "group-name",
                      value: formFields["groupName"],
                      onChange: (e) => dispatch(setBookingFormField("groupName", e.target.value))
                  }}
                />}

              <Button color="rose" onClick={handleSubmit}
                      disabled={!!bookingsLoading || isInvalid}>
                  {booking_type === "individual consult" ? "Book" : "Create Group Booking"}
              </Button>
          </form>
        );
    };

    const renderBookingTypeSelection = () => {
        return (
          <div>
              <Button
                color={booking_type === "individual consult" ? "rose" : "gray"}
                onClick={() => dispatch(setBookingFormField("bookingType", "individual consult"))}>
                  Individual Consult
              </Button>
              <Button
                color={booking_type === "group consult facilitator" ? "rose" : "gray"}
                onClick={() => dispatch(setBookingFormField("bookingType", "group consult facilitator"))}>
                  Group Consult
              </Button>
          </div>
        );
    };

    return (
      <div>
          <RenderAlert/>
          <GridContainer>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <EventIcon/>
                      </CardIcon>
                      <div style={{ display: "flex", alignItems: "center", margin: 15 }}>
                          <h4 className={classes.cardIconTitle}>Create a new Booking</h4>
                          {
                              bookingsLoading && <Loader
                                type="ThreeDots"
                                color="#00acc1"
                                height={100}
                                width={100}
                                timeout={15000} //15 secs
                                style={{ paddingLeft: "15" }}
                              />
                          }
                      </div>
                  </CardHeader>
                  <CardBody>
                      {renderBookingTypeSelection()}
                      {renderForm()}
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    );
}
