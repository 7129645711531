import axios from "axios";

import {
    API_REQUEST,
    API_GET,
    API_POST,
    API_PATCH,
    API_DELETE,
    apiAuthError,
    API_SET_HEADER,
    API_CLEAR_HEADER
} from '../../actions/api'

import {API_ROOT_URL} from "../../consts"

const REQUEST_TIMEOUT = 15 * 1000;

// let headers = {
//   Authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcmFjdGljZV9pZCI6IjY3SFFuNWs3NXl1ZU02Q0RSNUtqYmMiLCJpYXQiOjE1ODg5Mzk5MzQuOTIwNDg0LCJleHAiOjE1OTE1MzE5MzQuOTIwNDg0fQ.50N4dA0hzioUC-Xid6OjupruUU-HeJI00BpJWyrynWI"
// };

let headers = {};
const getOptions = (extraOptions) => {
    return {
        timeout: REQUEST_TIMEOUT,
        headers: headers,
        ...extraOptions
    };
};

const api = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([API_REQUEST, API_SET_HEADER, API_CLEAR_HEADER].includes(action.type)))
        return;

    switch (action.type) {
        case API_SET_HEADER:
            headers[action.payload.key] = action.payload.value;
            return;
        case API_CLEAR_HEADER:
            delete headers[action.payload];
            return;
        default:
            break;
    }

    if (action.payload === undefined)
        return;

    const {path, method, data, success, failure, full_url, extraOptions = {}, rawResponse = false} = action.payload;

    const api_url = full_url || `${API_ROOT_URL}/${path}`;

    let requestPromise = null;

    switch (method) {
        case API_GET:
            requestPromise = axios.get(api_url, getOptions(extraOptions));
            break;
        case API_POST:
            requestPromise = axios.post(api_url, data, getOptions(extraOptions));
            break;
        case API_DELETE:
            requestPromise = axios.delete(api_url, getOptions({...extraOptions, data: data}));
            break;
        case API_PATCH:
            requestPromise = axios.patch(api_url, data, getOptions(extraOptions));
            break;
        default:
            break;
    }

    if (requestPromise) {
        requestPromise
            .then(response => {
                if (success)
                    dispatch(success( rawResponse ? response : response.data));
            })
            .catch(error => {
                if (error.response && error.response.status !== undefined) {
                    if (error.response.status === 401 && error.response?.data?.error_type !== "unauthorised_action") {
                        dispatch(apiAuthError());
                    }
                }

                console.error(error);

                if (failure) {
                    dispatch(failure(error));
                }
            });
    }

};

export default api;
