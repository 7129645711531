import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {useDispatch, useSelector} from "react-redux";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Send from "@material-ui/icons/Send";
import Reply from "@material-ui/icons/Reply";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import {cardTitle} from "assets/jss/material-dashboard-pro-react.js";
import moment from "moment";

import {fetchLatestInstantBookings, performBookingAction, setAlert} from "redux/actions/bookings";
import {GROUP_VIDEO_URL, VIDEO_URL} from "redux/consts";

import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import {setInstantBookings} from "../../redux/actions/bookings";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";


const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    grid: {
        padding: "0 0px !important"
    },
    panelDescription: {
        fontSize: 'larger',
        marginBottom: '20px',
        color: '#3C4858',
    },
    action: {
        display: "flex",
        alignItems: "center"
    },
    actionText: {
        paddingLeft: 15
    },
    ...buttonStyle
};

const useStyles = makeStyles(styles);

export default function LatestInstantBookings() {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setInstantBookings({bookings: []}));
        dispatch(fetchLatestInstantBookings());

        return (() => {
            dispatch(setInstantBookings({bookings: []}));
        })
    }, []);

    const bookings = useSelector(state => state.bookings.instantBookingsList);
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const alert = useSelector(state => state.bookings.alert);

    const doAction = (bookingId, action) => {
        dispatch(performBookingAction(bookingId, action));
    };

    React.useEffect(() => {
        setData(
            bookings.map((booking, key) => (
                {
                    id: booking.id,
                    practitioner_name: booking.practitioner_name,
                    patient_name: booking.patient_name,
                    datetime: moment(booking.datetime).format("YYYY-MM-DD HH:mm"),
                    patient_contact_number: booking.patient_contact_number,
                    patient_email: booking.patient_email,
                    booking_type: booking.booking_type,
                    actions: (
                        <div className="actions-right">
                            <Button color="rose" onClick={() => {
                                window.open(`${booking.booking_type === "group consult facilitator" ? GROUP_VIDEO_URL : VIDEO_URL}/${booking.practitioner_token}`)
                            }}>Open Consult</Button>
                        </div>
                    )
                }
            ))
        );
    }, [bookings]);

    const [data, setData] = React.useState([]);

    const renderAlert = () => {

        if (!alert.show) return null;

        return (
            <SweetAlert
                success={alert.type === "success"}
                warning={alert.type === "warning"}
                style={{display: "block", marginTop: "-100px"}}
                title={alert.title}
                onConfirm={() => dispatch(setAlert({show: false}))}
                onCancel={() => dispatch(setAlert({show: false}))}
                confirmBtnCssClass={classes.button + " " + classes.success}
            >
                {alert.message}
            </SweetAlert>
        );
    };


    const classes = useStyles();
    return (
        <GridContainer>
            {
                renderAlert()
            }
            <GridItem xs={12} className={classes.grid}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Assignment/>
                        </CardIcon>
                        <div style={{display: "flex", alignItems: "center", margin: 15}}>
                            <h4 className={classes.cardIconTitle} style={{margin: 5}}>Latest instant bookings</h4>
                            {
                                bookingsLoading && <Loader
                                    type="ThreeDots"
                                    color="#00acc1"
                                    height={100}
                                    width={100}
                                    timeout={15000} //15 secs
                                    style={{paddingLeft: "15"}}
                                />
                            }

                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className={classes.panelDescription}>You can join the consult by clicking on the “OPEN
                            CONSULT” button next to the relevant instant booking.
                        </div>
                        <ReactTable
                            data={data}
                            showPaginationBottom={false}
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
                            }}
                            defaultSorted={[{id: "datetime", desc: true}]}
                            columns={[
                                {
                                    Header: "Date/Time",
                                    accessor: "datetime"
                                },
                                {
                                    Header: "Contact Number",
                                    accessor: "patient_contact_number",
                                },
                                {
                                    Header: "Email",
                                    accessor: "patient_email"
                                },
                                {
                                    Header: "",
                                    accessor: "booking_type",
                                    filterable: false,
                                    width: 50,
                                    resizable: false,
                                    Cell: row => (
                                        <div>
                                            {row.original.booking_type === "individual consult" ? <Person/> : <Group/>}
                                        </div>
                                    )
                                },
                                {
                                    Header: "",
                                    accessor: "actions",
                                    sortable: false,
                                    filterable: false,
                                    width: 250,
                                    resizable: false
                                }
                            ]}
                            defaultPageSize={3}
                            className="-striped -highlight"
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
