import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "../../../components/Grid/GridContainer";
import RenderAlert from "../renderAlert";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import Loader from "react-loader-spinner";
import CardBody from "../../../components/Card/CardBody";
import {cardTitle} from "../../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import {fetchPractitionerAvailability, rescheduleCalendarBooking, rescheduleBooking} from "../../../redux/actions/bookings";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Replay from "@material-ui/icons/Replay";
import Close from "@material-ui/icons/Close";
import bookingStyles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);

const Content = (data) => {
    data = data.data
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const [dateTime, setDateTime] = React.useState(null);
    const [dateTimeSlot, setDateTimeSlot] = React.useState(null);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (data?.practitioner?.is_calendar_user) dispatch(fetchPractitionerAvailability(data?.practitioner))
    }, []);

    const handle_on_submit = () => {
        if (data?.practitioner?.is_calendar_user){
            dispatch(rescheduleCalendarBooking({slot_id: dateTimeSlot.slot_id, rescheduled_by: data?.practitioner?.name, id: data?.booking_id}))
        }
        else{
            dispatch(rescheduleBooking({datetime: dateTime.format(), rescheduled_by: data?.practitioner?.name, id: data?.booking_id}))
        }
    }

    const CalendarUserDateTimeSelect = () => {
        const useStyles = makeStyles(bookingStyles);
        const classes = useStyles();


        const calendar_user_availability = useSelector(state => state.bookings.calendar_user_availability);
        const hasBookingSlots = !!(calendar_user_availability?.length)
        return ( <>
            <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        >

                      <Autocomplete
                        options={calendar_user_availability ? calendar_user_availability : []}
                        getOptionLabel={(p) => p ? `${moment(p.start).format('dddd, DD MMMM YYYY')}, ${moment(p.start).format('HH:mm')} - ${moment(p.end).format('HH:mm A')}` : ""}
                        value={dateTimeSlot}
                        onChange={(event, newValue) => setDateTimeSlot(newValue)}
                        openOnFocus={true}
                        groupBy={(p) => `${moment(p.start).format('dddd, DD MMMM YYYY')}`}
                        renderOption={(o) => <p>{moment(o.start).format('HH:mm')} - {moment(o.end).format('HH:mm A')}</p>}
                        disabled={!!bookingsLoading || !hasBookingSlots}
                        renderInput={(params) => <TextField
                          label={ bookingsLoading ? "Please wait..." : (hasBookingSlots ? "Choose a Timeslot" : "No Booking Slots Available")}
                          {...params}
                          InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment
                                    style={ bookingsLoading ? {marginRight: "-18px"} : (hasBookingSlots? {marginRight: "-56px"}: {marginRight: "-28px"}) }
                                    position="end">
                                    { bookingsLoading ?
                                    <Loader
                                        type="ThreeDots"
                                        color="#00acc1"
                                        width={25}
                                        timeout={15000} //15 secs
                                    /> :
                                    <IconButton onClick={() => dispatch(fetchPractitionerAvailability(data?.practitioner))}>
                                      <Replay />
                                    </IconButton>
                                    }

                                </InputAdornment>
                              ),
                              autoComplete: "new-password" // Avoid Auto Complete
                          }}
                          InputLabelProps={{ classes: {root: classes.endAdornment}}}
                        />}
                      />
                    </FormControl>
        </>
        )
    };

    const renderTimeSelect = (data) => {
        if (data !== null) return data?.practitioner?.is_calendar_user ? <CalendarUserDateTimeSelect/> :
            <>
                <InputLabel className={classes.label}>Select a date and time</InputLabel>
                  <br/>
                <FormControl fullWidth>
                      <Datetime
                        inputProps={{ placeholder: "" }}
                        timeConstraints={{
                            minutes: {
                                step: 5
                            }
                        }}
                        value={dateTime}
                        onChange={(dt) => setDateTime(dt)}
                        dateFormat={"dddd, MMMM Do YYYY [at]"}
                        timeFormat={"h:mm a"}
                        isValidDate={(current) => current.isAfter(Datetime.moment().subtract(1, "day"))}
                      />
                    </FormControl>
            </>
        return null;
    };

    const classes = useStyles();
    return (
      <div>
          <RenderAlert/>
          <GridContainer>
              <Card style={{height: "450px"}}>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <EventIcon/>
                      </CardIcon>
                      <div style={{ display: "flex", alignItems: "center", margin: 15 }}>
                          <h4 className={classes.cardIconTitle}>Reschedule a Booking</h4>
                          {
                              bookingsLoading && <Loader
                                type="ThreeDots"
                                color="#00acc1"
                                height={100}
                                width={100}
                                timeout={15000} //15 secs
                                style={{ paddingLeft: "15" }}
                              />
                          }
                      </div>
                  </CardHeader>
                  <CardBody>
                      {renderTimeSelect(data)}
                      <Button color="rose" onClick={() => handle_on_submit()} disabled={!!bookingsLoading || data?.practitioner?.is_calendar_user ? !dateTimeSlot : !dateTime}>
                          Reschedule
                      </Button>
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    )
}

const RescheduleDialog = ({data, open, onClose}) => {

    return (
        <Dialog open={open} scroll="paper" fullWidth={true} maxWidth="xl">
            <DialogActions style={{ height: "50px"}}>
                <Button color="transparent" onClick={onClose} style={{ color:"#aa2428"}}>
                    <Close />
                </Button>
            </DialogActions>
            <DialogContent>
                <Content data={data}/>
            </DialogContent>

        </Dialog>
    )
}

export default RescheduleDialog;