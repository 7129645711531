import {
    SET_LOGIN_INFO,
    AUTHENTICATION_LOGIN,
    AUTHENTICATION_LOGIN_SUCCESS,
    AUTHENTICATION_LOGIN_FAILED,
    SET_LOGGING_IN,
    AUTHORIZE,
    AUTHORIZE_SUCCESS,
    AUTHORIZE_FAILED,
} from "../actions/auth";

const initState = { login_info: {}, loginFailed: false, loggingIn: false };

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_LOGIN_INFO:
            return { ...state, login_info: action.payload };
        case SET_LOGGING_IN:
            return { ...state, loggingIn: action.payload };
        case AUTHENTICATION_LOGIN:
            return { ...state, loginFailed: false };
        case AUTHENTICATION_LOGIN_SUCCESS:
            return { ...state, loginFailed: false };
        case AUTHENTICATION_LOGIN_FAILED:
            return { ...state, loginFailed: true };
        case AUTHORIZE:
            return { ...state, loginFailed: false };
        case AUTHORIZE_SUCCESS:
            return { ...state, loginFailed: false };
        case AUTHORIZE_FAILED:
            return { ...state, loginFailed: true };
        default:
            return state;
    }
};