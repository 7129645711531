export const AUTHENTICATION_LOGIN = "AUTHENTICATION_LOGIN";
export const AUTHENTICATION_LOGIN_SUCCESS = "AUTHENTICATION_LOGIN_SUCCESS";
export const AUTHENTICATION_LOGIN_FAILED = "AUTHENTICATION_LOGIN_FAILED";
export const SET_LOGIN_INFO = "SET_LOGIN_INFO";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_LOGGING_IN = "SET_LOGGING_IN";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const FETCH_AUTHENTICATED_USER_FROM_STORAGE = "FETCH_AUTHENTICATED_USER_FROM_STORAGE";
export const AUTHENTICATED_USER_FETCHED_FROM_STORAGE = "AUTHENTICATED_USER_FETCHED_FROM_STORAGE";

export const AUTHORIZE = "AUTHORIZE";
export const AUTHORIZE_SUCCESS = "AUTHORIZE_SUCCESS";
export const AUTHORIZE_FAILED = "AUTHORIZE_FAILED";

export const login = ({ email, password }) => ({ type: AUTHENTICATION_LOGIN, payload: { email, password } });
export const loginSuccess = (token) => ({ type: AUTHENTICATION_LOGIN_SUCCESS, payload: token });
export const loginFailure = () => ({ type: AUTHENTICATION_LOGIN_FAILED });

export const setLoginInfo = (info) => ({ type: SET_LOGIN_INFO, payload: info });
export const logoutUser = () => ({ type: LOGOUT_USER });
export const setLoggingIn = (value) => ({ type: SET_LOGGING_IN, payload: value });
export const resetPassword = (email) => ({ type: RESET_PASSWORD, payload: email });

export const fetchUserFromStorage = () => ({ type: FETCH_AUTHENTICATED_USER_FROM_STORAGE });
export const userFetchedFromStorage = (user) => ({ type: AUTHENTICATED_USER_FETCHED_FROM_STORAGE, payload: user });

export const authorize = (payload) => ({ type: AUTHORIZE, payload })
export const authorizeSuccess = (payload) => ({ type: AUTHORIZE_SUCCESS, payload });
export const authorizeFailed = (payload) => ({ type: AUTHORIZE_FAILED, payload });