import BookingForm from "views/Booking/CreateBooking/BookingForm";
import InstantConsult from "views/InstantConsult/InstantConsult";
import Bookings from "views/Booking/Bookings";
import WorkingHours from "views/WorkingHours/WorkingHours";
import Consults from "views/Consults/Consults";
import LoginPage from "views/Pages/LoginPage.js";

// @material-ui/icons

import EventIcon from "@material-ui/icons/Event";
import ListIcon from "@material-ui/icons/List";
import VideoCallIcon from '@material-ui/icons/VideoCall';
import SettingsIcon from '@material-ui/icons/Settings';
import WatchRoundedIcon from '@material-ui/icons/WatchRounded';
import AvTimerSharpIcon from '@material-ui/icons/AvTimerSharp';
import Settings from "./views/Settings/Settings";
import EditIcon from "@material-ui/icons/Edit";

const anyRoleFilter = (roles) => roles.some(r => true);
const bookingsClerkFilter = (roles) => roles.some(r => r.startsWith('bookings:/'));
const practitionerFilter = (roles) => roles.some(r => r === 'practitioner');
const hiddenFilter = (roles) => (false);

const routes = [
    {
        path: "/booking",
        name: "Book a Consult",
        icon: EventIcon,
        component: BookingForm,
        layout: "/admin",
        filter: bookingsClerkFilter
    },
    {
        path: "/manage_bookings",
        name: "Manage Bookings",
        icon: ListIcon,
        component: Bookings,
        layout: "/admin",
        filter: bookingsClerkFilter
    },
    {
        path: "/working_hours",
        name: "Working Hours",
        icon: WatchRoundedIcon,
        component: WorkingHours,
        layout: "/admin",
        filter: anyRoleFilter
    },
    {
        path: "/login",
        name: "Login Page",
        mini: "L",
        component: LoginPage,
        layout: "/auth",
        redirect: true
    },
    {
        path: '/pending_consults',
        name: 'Pending Consults',
        icon: VideoCallIcon,
        component: Consults,
        layout: "/admin",
        filter: practitionerFilter
    },
    {
        path: "/instant_consult",
        name: "Instant Consult",
        icon: AvTimerSharpIcon,
        component: InstantConsult,
        layout: "/admin",
        filter: practitionerFilter
    }
    // {
    //     path: '/settings',
    //     name: 'Settings',
    //     icon: SettingsIcon,
    //     component: Settings,
    //     layout: "/settings",
    //     filter: practitionerFilter
    // }

];
export default routes;
