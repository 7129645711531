import React from 'react';

import {useSelector, useDispatch} from "react-redux";
import {logoutUser, fetchUserFromStorage} from "redux/actions/auth";

const withAutoLogout = (WrappedComponent) => {
    const AutoLogout = (props) => {

        const dispatch = useDispatch();
        const { token_data } = useSelector(state => state.auth.login_info);
        const exp = token_data?.exp || null;
        const roles = token_data?.roles || [];

        React.useEffect(() => dispatch(fetchUserFromStorage()), []);


        const logout = () => dispatch(logoutUser());

        React.useEffect(() => {

            if (exp === null) return () => {};

            const currentTimeMillis = (new Date()).getTime();
            const logoutTimeMillis = (exp)*1000;

            if (currentTimeMillis > logoutTimeMillis) {
                logout()
            } else {
                const timer = setTimeout(() => {
                    logout()
                }, (logoutTimeMillis-currentTimeMillis));
                return () => clearTimeout(timer);
            }
        }, [exp]);

        if (exp === null) {
            return <div></div>;
        }

        return <WrappedComponent
          logout={logout}
          roles={roles}
          {...props} />
    }
    return AutoLogout;
};

export default withAutoLogout;