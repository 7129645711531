import React, {useEffect, useState} from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";

// @material-ui/icons
import EventIcon from "@material-ui/icons/Event";
import AvTimerSharpIcon from '@material-ui/icons/AvTimerSharp';

// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";

import {
    clearBookingsForm,
    makeInstantBooking,
    setBookingFormField,
} from "../../redux/actions/bookings";


import RenderAlert from "./renderAlert";
import CustomInput from "../../components/CustomInput/CustomInput";
import LatestInstantBookings from "./LatestInstantBookings"


const useStyles = makeStyles(styles);
export default function ExtendedForms() {

    const dispatch = useDispatch();


    const alert = useSelector(state => state.bookings.alert);
    const bookingsLoading = useSelector(state => state.bookings.bookingsLoading);
    const userName = useSelector(s => s.auth?.login_info?.token_data?.user?.name);

    const formFields = useSelector(state => state.bookings.bookingFormFields);

    const duplicateParticipant = formFields && formFields['selectedPractitioner'] && formFields['selectedPractitioner']['name'] === formFields['name'];

    const isFormInvalid = (formFields) => {
        if (formFields["email"] === "" && formFields["contactNumber"] === "")
            return true;
        return false;
    };

    const isInvalid = isFormInvalid(formFields) || duplicateParticipant;

    const [communication_type, setCommunicationType] = useState(formFields['communicationType'] || 'SMS');
    React.useEffect(() => {
        dispatch(clearBookingsForm());
        dispatch(setBookingFormField("datetime", Datetime.moment().subtract(1, "day")))
    }, []);

    useEffect(() => {
        if (formFields['communicationType'] !== communication_type) {
            setCommunicationType(formFields['communicationType'])
        }
    }, [formFields['communicationType']]);

    if (formFields['datetime'] < Datetime.moment().startOf('day')) {
        const remainder = 5 - (Datetime.moment().minute() % 5);
        dispatch(setBookingFormField("datetime", Datetime.moment().add(remainder, "minute")))
    }


    const classes = useStyles();


    const handleSubmit = () => {
        const payload = {
            datetime: moment(),//formFields["datetime"].format(),
            patient_contact_number: formFields['communicationType'] === 'SMS' ? formFields["contactNumber"] : "",
            patient_email: formFields['communicationType'] === 'Email' ? formFields["email"] : "",
            booking_created_by: userName,
            communication_type: formFields['communicationType']
        };
        dispatch(makeInstantBooking(payload));
    };

    const renderForm = () => {

        return (
            <form>
                <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                >
                    {communication_type === 'SMS' ? <CustomInput
                            labelText="Patient's Contact Number"
                            id="patient_contact_number"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "tel",
                                autoComplete: "patients-contact-number",
                                value: formFields["contactNumber"],
                                onChange: (e) => dispatch(setBookingFormField("contactNumber", e.target.value))
                            }}
                        /> :
                        <CustomInput
                            labelText="Patient's Email Address"
                            id="patient_email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "email",
                                autoComplete: "patients-email",
                                value: formFields["email"],
                                onChange: (e) => dispatch(setBookingFormField("email", e.target.value))
                            }}
                        />}
                </FormControl>

                <Button color="rose" onClick={handleSubmit}
                        disabled={!!bookingsLoading || isInvalid}
                >
                    Book
                </Button>
            </form>
        );
    };

    const renderCommunicationTypeSelection = () => {
        return (
            <div>
                <Button
                    color={communication_type === "SMS" ? "rose" : "gray"}
                    onClick={() => dispatch(setBookingFormField("communicationType", "SMS"))}>
                    SMS
                </Button>
                <Button
                    color={communication_type === "Email" ? "rose" : "gray"}
                    onClick={() => dispatch(setBookingFormField("communicationType", "Email"))}>
                    Email
                </Button>
            </div>
        );
    };

    return (
        <>
            <div>
                <RenderAlert/>
                <GridContainer>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose">
                                <AvTimerSharpIcon/>
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Create an Instant Booking</h4><br/>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.panelDescription}>Create an instant booking by selecting the
                                communication method and inputting the patients details. Please note that this is defaulted
                                at SMS.
                            </div>
                            {renderCommunicationTypeSelection()}
                            {renderForm()}
                        </CardBody>
                    </Card>

                </GridContainer>
                <GridContainer>
                </GridContainer>
            </div>
            <LatestInstantBookings/>
        </>
    );
}
