import jwtDecode from "jwt-decode";

import { apiRequest, API_GET, API_POST, API_AUTHENTICATION_ERROR, setHeader, clearHeader } from "../../actions/api";
import {
    AUTHENTICATED_USER_FETCHED_FROM_STORAGE,
    AUTHENTICATION_LOGIN_FAILED,
    AUTHENTICATION_LOGIN_SUCCESS,
    AUTHENTICATION_LOGIN,
    LOGOUT_USER,
    RESET_PASSWORD,
    AUTHORIZE,
    AUTHORIZE_SUCCESS,
    AUTHORIZE_FAILED,
    FETCH_AUTHENTICATED_USER_FROM_STORAGE,
    authorize,
    authorizeFailed,
    authorizeSuccess,
    loginFailure,
    loginSuccess,
    logoutUser,
    setLoggingIn,
    setLoginInfo,
    userFetchedFromStorage,
} from "../../actions/auth";

import { saveToLocalStorage, fetchFromLocalStorage, removeFromLocalStorage } from "../../actions/storage";

import { DATA_TYPE_JSON } from "../core/storage";
import { push } from "../core/router";

import { AUTHENTICATION_URL, AUTHENTICATION_CLIENT_ID, AUTHENTICATION_AUDIENCE, AUTHENTICATION_CONNECTION, API_ROOT_URL } from "../../consts";

const ACTION_TYPES_TO_PROCESS = [
    AUTHENTICATION_LOGIN, AUTHENTICATION_LOGIN_SUCCESS,
    API_AUTHENTICATION_ERROR, LOGOUT_USER,
    FETCH_AUTHENTICATED_USER_FROM_STORAGE,
    AUTHENTICATED_USER_FETCHED_FROM_STORAGE,
    AUTHENTICATION_LOGIN_FAILED, RESET_PASSWORD,
    AUTHORIZE, AUTHORIZE_SUCCESS, AUTHORIZE_FAILED
];

const auth = ({ dispatch, getState }) => next => action => {
    if (!action)
        return;

    next(action);

    if (!(ACTION_TYPES_TO_PROCESS.includes(action.type)))
        return;

    switch (action.type) {
        case AUTHENTICATION_LOGIN:
            next(setLoggingIn(true));
            next(
              apiRequest({
                  full_url: `${AUTHENTICATION_URL}/oauth/token`,
                  method: API_POST,
                  data: {
                      grant_type: "password",
                      username: action.payload.email,
                      password: action.payload.password,
                      audience: AUTHENTICATION_AUDIENCE,
                      scope: "openid",
                      client_id: AUTHENTICATION_CLIENT_ID
                  },
                  success: loginSuccess,
                  failure: loginFailure
              })
            )
            break;
        case AUTHENTICATION_LOGIN_SUCCESS: {
            dispatch(authorize({ ...action.payload }));
            break;
        }
        case AUTHENTICATION_LOGIN_FAILED:
            next(setLoggingIn(false));
            break;
        case LOGOUT_USER:
            next(removeFromLocalStorage("user"));
            next(clearHeader("Authorization"));
            next(setLoginInfo({}));
            next(push("/auth/login"));
            break;
        case API_AUTHENTICATION_ERROR:
            dispatch(logoutUser());
            break;
        case FETCH_AUTHENTICATED_USER_FROM_STORAGE:
            next(fetchFromLocalStorage({ key: "user", type: DATA_TYPE_JSON, onFetch: userFetchedFromStorage }));
            break;
        case AUTHENTICATED_USER_FETCHED_FROM_STORAGE:
            if (action.payload) {
                next(setLoginInfo(action.payload || {}));
                next(setHeader({ key: "Authorization", value: action.payload.authorization_token }));
            } else {
                dispatch(logoutUser());
            }
            break;

        case RESET_PASSWORD:
            next(
              apiRequest({
                  full_url: `${AUTHENTICATION_URL}/dbconnections/change_password`,
                  method: API_POST,
                  data: {
                      email: action.payload,
                      connection: AUTHENTICATION_CONNECTION,
                      client_id: AUTHENTICATION_CLIENT_ID
                  },
              })
            );
            break;
        case AUTHORIZE:
            next(
                apiRequest({
                    full_url: `${API_ROOT_URL}/authorize`,
                    method: API_POST,
                    data: action.payload,
                    success: authorizeSuccess,
                    failure: authorizeFailed,
                })
            );
            break;
        case AUTHORIZE_SUCCESS: {
            const loginInfo = {
                authorization_token: action.payload.authorization_token,
                token_data: jwtDecode(action.payload.authorization_token),
            }
            next(setLoggingIn(false));
            next(setLoginInfo(loginInfo));
            next(setHeader({ key: "Authorization", value: action.payload.authorization_token }))
            next(saveToLocalStorage({ key: "user", type: DATA_TYPE_JSON, value: loginInfo}));
            next(push("/"));
            break;
        }
        case AUTHORIZE_FAILED:
            next(setLoggingIn(false));
            dispatch(logoutUser());
            break;
        default:
            break;
    }

};

export default auth;
